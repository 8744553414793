<template>
  <div class="allmsgclass">
    <p class="msg">
      <span class="wan">完善信息</span>
      <!-- <span class="xia" @click="formcheck">下一步</span> -->
    </p>
    <div class="identity">
      <p class="tian">填写身份信息</p>
      <p class="buddha">
        <span>头像</span>
        <!-- 女孩 -->
        <img
          class="icon"
          v-if="become == 2"
          src="../assets/userss.png"
          alt=""
        />
        <!-- 男孩 -->
        <img class="icon" v-if="become == 1" src="../assets/boy.png" alt="" />
      </p>
      <p class="buddha">
        <span class="sr ss">姓名</span>
        <input
          class="lian"
          v-model="username"
          type="text"
          style="text-align: right"
          disabled
        />
      </p>
      <div class="sexs">
        <span class="se ss">性别</span>
        <p class="sex">
          <span
            class="nan"
            :class="{ select: become == 1, select1: become == 2 }"
            @click="become = 1"
            >男</span
          >
          <span
            class="nv"
            :class="{ select: become == 2, select1: become == 1 }"
            @click="become = 2"
            >女</span
          >
        </p>
      </div>

      <div class="buddha">
        <!-- <van-cell title="选择单个日期" :value="date" @click="bbb" />
          <van-calendar v-model="showes" @confirm.prevent.stop="aaa" /> -->
        <div class="sr ss">出生年月</div>
        <input
          readonly="readonly"
          class="lian"
          id="phone"
          style="text-align: right"
          v-model="birthday"
          placeholder="请输入出生年月"
          @click="shows = true"
        />
      </div>

      <van-popup v-model="shows" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="qx"
          @confirm="qd"
        />
      </van-popup>
      <p class="buddha">
        <span class="sr ss">户籍所在地</span>
        <input
          class="lian"
          id="phone"
          type="text"
          style="text-align: right"
          v-model="census"
          placeholder="请输入户籍所在地"
        />
      </p>
      <p class="buddha">
        <span class="sr ss">手机号</span>
        <input
          class="lian xx"
          id="phone"
          type="text"
          style="text-align: right"
          v-model="mobile"
          disabled
        />
      </p>
      <p class="buddha">
        <span class="sr">联系邮箱</span>
        <input
          type="text"
          class="lian"
          v-model="mailbox"
          style="text-align: right"
          placeholder="请填写邮箱"
          @input="mail"
        />
      </p>
      <p class="buddha">
        <span class="sr ss">期望薪资(元)</span>
        <input
        readonly="readonly"
          class="lian xx"
          id="phone"
          type="text"
          style="text-align: right"
          v-model="pay"
          @click="show = true"
          placeholder="请选择期望薪资"
        />
      </p>
      <van-action-sheet v-model="show" title="">
        <div class="content">
          <van-picker
            title=""
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
          />
        </div>
      </van-action-sheet>
    </div>
    <div>
      <p class="btn" @click="formcheck">下一步</p>
    </div>
    <div style="height:.3rem"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mailbox: "",
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(1990,0,1),
      shows: false,
      show: false,
      columns: [
        "面议",
        // "5000(元)以下",
        "5000-10000(元)",
        "10000-15000(元)",
        "15000-20000(元)",
        "20000-25000(元)",
        "25000-30000(元)",
        "30000-35000(元)",
        "35000-40000(元)",
        "40000-50000(元)",
        "50000-60000(元)",
        "60000(元)以上"
      ],
      become: 1,
      username: "",
      pay: "",
      birthday: "",
      mobile: "",
      sex: "",
      census: "", //户籍
      allmsg:""
    };
  },
  created() {
    let id = localStorage.getItem("id");
    this.census = localStorage.getItem("census");
    this.pay = localStorage.getItem("pay");
    this.mailbox = localStorage.getItem("mailbox");
    this.$http
      .post("/firm/v1/resume/getResumeInfo", {
        reqType: "resume",
        id: id,
      })
      .then((res) => {
        console.log(JSON.parse(res.data));
        res = JSON.parse(res.data);
        this.allmsg = res.data;
        localStorage.setItem("allmsg",JSON.stringify(res.data));
        this.become = res.data.sex == "女" ? 2 : 1;
        this.sex = res.data.sex;
        this.username = res.data.name;
        this.mobile = res.data.mobile;
        this.birthday = res.data.birthday;
        this.census = res.data.household_register;
        this.pay = res.data.salary;
        this.mailbox = res.data.email;
        if (res.data.id_no) {
          this.birthday =
            res.data.id_no.substring(6, 10) +
            "-" +
            res.data.id_no.substring(10, 12) +
            "-" +
            res.data.id_no.substring(12, 14);
          console.log(res);
        } else {
          this.birthday;
        }
      })
      .catch((eer) => {
        console.log(eer);
      });
  },
  methods: {
    //同步缓存邮件
    mail() {
      localStorage.setItem("mailbox", this.mailbox);
    },
    //取消选择
    qx() {
      this.shows = false;
    },
    //确定选择
    qd(val) {
      console.log(val);
      let time = new Date(val);
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let date = time.getDate();
      // month = month.length == 1 ? "0" + month : month;
      // date = date.length == 1 ? "0" + time : time;
      this.birthday = year + "-" + month + "-" + date;
      console.log(month);
      this.shows = false;
    },
    //确认选择薪资
    onConfirm(value, index) {
      this.pay = value;
      this.show = false;
    },
    //取消选择薪资
    onCancel() {
      this.show = false;
    },
    //保存进入下一步
    formcheck() {
      if (this.username == "") {
        this.$toast.fail("请输入姓名");
        return;
      }
      if (this.pay == "") {
        this.$toast.fail("请输入薪资");
        return;
      }
      console.error(this.census)
      if (this.census == ""||this.census=='undefined'||this.census=='null') {
        this.$toast.fail("请输入户籍");
        return;
      }
      if(this.birthday==""){
        this.$toast.fail("请选择出生年月");
        return;
      }
      if(this.mobile==""){
        this.$toast.fail("手机号为空，请联系客服！");
        return;
      }
      localStorage.setItem("area",this.allmsg.province?this.allmsg.province+'/'+this.allmsg.city:'');
      localStorage.setItem("username", this.username);
      localStorage.setItem("pay", this.pay);
      // localStorage.setItem("sex", this.sex);
      localStorage.setItem("sex", this.become == 1 ? "男" : "女");
      localStorage.setItem("birthday", this.birthday);
      localStorage.setItem("mobile", this.mobile);
      localStorage.setItem("census", this.census);
      localStorage.setItem("mailbox", this.mailbox);
      let id = localStorage.getItem("id");
      this.$http
        .post("/firm/v1/Resume/improveInfo", {
          reqType: "resume",
          id: id,
          household_register: this.census,
          name: this.username,
          salary: this.pay,
          sex: this.become == 1 ? "男" : "女",
          birthday: this.birthday,
          email: this.mailbox,
          mobile: this.mobile,
          pageno: 1, //页码
        })
        .then((res) => {
          res = JSON.parse(res.data);
          console.log(res);
          if (res.code == 0) {
            this.$router.push("/resume");
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((eer) => {
          console.log(eer);
        });
    },
  },
};
</script>

<style scoped>
.allmsgclass{
  overflow: hidden;
}
input {
  outline: none;
  width: 4.5rem !important;
}
input:disabled {
  background-color: #fff;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}

.msg span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}

.msg .xia {
  float: right;
  margin-top: 0.24rem;
  margin-right: 0.3rem;
}

.msg .wan {
  margin-right: 0.3rem;
  margin-left: 3.07rem;
}

.tian {
  font-size: 0.45rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #616b80;
  margin-top: 0.38rem;
}

.identity {
  margin: 0rem 0.32rem;
}

.identity .buddha {
  /* display: flex; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.46rem;
}

.identity .buddha .icon {
  float: right;
  width: 1.08rem;
  height: 1.08rem;
  margin: 0.19rem 0rem;
}

.identity .buddha .lian {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
}

.identity .sexs {
  display: flex;
  height: 1.46rem;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  /* line-height: 1.46rem; */
}

.identity .se {
  margin-top: 0.52rem;
  white-space: nowrap;
}

.identity .sexs .sex {
  width: 3.07rem;
  height: 0.74rem;
  border: solid 0.01rem #f55613;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f55613;
  margin-top: 0.36rem;
  line-height: 0.74rem;
  margin-left: 2.9rem;
  display: flex;
}

.identity .sexs .sex .nv {
  display: block;
  width: 1.54rem;
  color: #ffffff;

  text-align: center;
}
.select {
  color: #fff;
  background: #f55613;
}
.select1 {
  color: #f55613 !important;
  background: #fff;
}
.identity .sexs .sex .nan {
  display: block;
  width: 1.54rem;
  text-align: center;
}

.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}
.ss{
  white-space: nowrap;
}


.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
}

.btn {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-top: .5rem;
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
